import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Card } from 'pcln-design-system'
import {
  IMediaCard,
  IGoogleAnalyticsTrackingType
} from '@pcln/contentful-components'
import Head from 'next/head'
import { ModalContent } from '@/types'
import { MediaCard } from '@pcln/marketing'
import analytics from '@/shared-utils/analytics'
import MoreInfoModal from '../Modal/MoreInfoModal'

const cardStyles = css`
  transition: 0.2s ease-in all;
  &:hover {
    transform: scale(1.02);
  }
`
const StyledCard = styled(Card)`
  ${cardStyles}
`

export default function MediaCardComponent({
  content
}: {
  content: IMediaCard
}) {
  const {
    heading,
    overline,
    subheading,
    media,
    analyticsTracking,
    primaryCallToAction,
    coupon
  } = content

  const couponProp =
    coupon?.couponCodeText && !primaryCallToAction
      ? { coupon: coupon.couponCodeText }
      : undefined
  const [isOpen, setIsOpen] = useState(false)
  const [currentModalProps, setCurrentModalProps] = useState<ModalContent>()
  const handleOpen = (
    modalProps: ModalContent,
    gaTracking: IGoogleAnalyticsTrackingType
  ) => {
    const ga4Parameters = gaTracking?.parameters
    if (ga4Parameters) {
      analytics.fireGA4Event({
        event: 'select_promotion',
        attributes: ga4Parameters?.attributes
      })
    }
    if (modalProps.body || modalProps.heading || modalProps.paragraph) {
      setCurrentModalProps(modalProps)
      setIsOpen(true)
    }
  }

  const handleClose = () => {
    setCurrentModalProps(undefined)
    setIsOpen(false)
  }

  useEffect(() => {
    const ga4Parameters = analyticsTracking?.parameters
    if (ga4Parameters) {
      analytics.fireGA4Event(ga4Parameters)
    }
  }, [analyticsTracking])

  return (
    <>
      {media?.file.url && (
        <Head>
          <link
            rel="preload"
            as="image"
            fetchPriority="high"
            href={`${media.file.url}?fm=webp&w=600&h=200&q=80`}
          />
        </Head>
      )}
      <MoreInfoModal
        {...currentModalProps}
        isFullHeight={false}
        isOpen={isOpen}
        handleClose={() => {
          handleClose()
        }}
      />
      <StyledCard borderWidth={0} width={[1, null, 1 / 2]}>
        <MediaCard
          boxShadowSize="md"
          handleOpen={handleOpen}
          lazyLoad={false}
          asset={{
            file: {
              contentType: 'Image',
              details: {
                image: {
                  height: 200,
                  width: 600
                }
              },
              fileName: media?.file?.fileName,
              url: media?.file.url
                ? `${media.file.url}?fm=webp&w=600&h=200&q=80`
                : undefined
            },
            title: media?.title
          }}
          callToAction={primaryCallToAction}
          heading={heading}
          overline={overline}
          subheading={subheading}
          objectFit="cover"
          objectPosition="top"
          coupon={couponProp}
        />
      </StyledCard>
    </>
  )
}
