import React from 'react'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import { isIMerchModule } from '@/shared-utils/content-model-type-guards'
import MerchModuleWrapper from '../../MerchModuleWrapper'

export default function MarketingTout() {
  const marketingTout = useContentfulVariation('marketingTout', isIMerchModule)

  return marketingTout != null ? (
    <MerchModuleWrapper content={marketingTout} />
  ) : null
}
