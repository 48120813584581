import React from 'react'
import styled from 'styled-components'
import { FormField, Label, Select, theme } from 'pcln-design-system'
import useBootstrapData from '@/hooks/useBootstrapData'
import { locales, validateLocale } from './dateFnsLocales'
import capitalizeLetter from '../../utils/capitalize-letter'

const StyledSelect = styled(Select)`
  background: ${theme.colors.white};
  cursor: pointer;
  height: 56px;
`

const NOON = 24
const HOURS = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30'
]

const parseStandardTime = (time: string) => {
  const [hours, minutes] = time.split(':')
  const standardHours =
    Number(hours) > 12 ? (Number(hours) - 12).toString() : hours
  return `${standardHours}:${minutes}`
}

const generateTimeSelectOptions = (locale: string) => {
  const dateFnsLocale = locales[validateLocale(locale)]
  const am = dateFnsLocale.localize?.dayPeriod('am', {
    width: 'abbreviated'
  })
  const pm = dateFnsLocale.localize?.dayPeriod('pm', {
    width: 'abbreviated'
  })
  const noon = dateFnsLocale.localize?.dayPeriod('noon', {
    width: 'abbreviated'
  })
  const midnight = dateFnsLocale.localize?.dayPeriod('midnight', {
    width: 'abbreviated'
  })
  const timeFormat = dateFnsLocale.formatLong?.time({ width: 'short' })

  return HOURS.map((time, index) => {
    const isBeforeNoon = index < NOON
    const meridiem = isBeforeNoon ? am : pm
    let label =
      timeFormat === 'HH:mm' ? time : `${parseStandardTime(time)} ${meridiem}`

    if (time === '00:00') {
      label = capitalizeLetter(midnight)
    }
    if (time === '12:00') {
      label = capitalizeLetter(noon)
    }

    return (
      <option key={time} value={time} label={label}>
        {label}
      </option>
    )
  })
}

type TimeSelectProps = {
  label: string
  name: string
  onChange: (e: React.ChangeEvent) => void
  value: string
}

function TimeSelect({ label, name, onChange, value }: TimeSelectProps) {
  const { locale: { localeCode = 'en-US' } = {} } = useBootstrapData()

  return (
    <FormField>
      <Label autoHide>{label}</Label>
      <StyledSelect id={name} name={name} onChange={onChange} value={value}>
        {generateTimeSelectOptions(localeCode)}
      </StyledSelect>
    </FormField>
  )
}

export default TimeSelect
