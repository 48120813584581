import * as yup from 'yup'
import { formatDate } from '@/shared-utils/date-helper'
import { DRIVE, FLY, STAY } from '../constants'
import { CAR_FORM_STATE_TYPE } from '../types'

export const CHILD_AGE_REQUIRED = "Please enter children's age"
const DATE_RANGE_ERROR =
  'Please make sure the drop-off date is the same or later than the pick-up date'
export const DROPOFF_LOCATION_REQUIRED = 'Please select a drop-off location'
export const INVALID_DATE = 'Please select a valid date'
export const INVALID_DATES = 'Please select valid dates'
export const HOTEL_LOCATION_REQUIRED = 'Please enter a valid location'
export const PICKUP_LOCATION_REQUIRED = 'Please select a pick-up location'
const MAXIMUM_BUNDLE_GUEST_COUNT = 8
const BUNDLE_MAX_CAPACITY_ERROR = `Bundles accommodate up to ${MAXIMUM_BUNDLE_GUEST_COUNT} people. You may need an additional bundle for your group`

function isChildrenAgeProvided(this: yup.TestContext) {
  const { childrenAges, childrenCount } = this.parent
  return childrenCount === childrenAges.length
}

function isWithinDateRange(this: yup.TestContext) {
  const { endDate, startDate } = this.parent
  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(endDate)

  return new Date(formattedStartDate) <= new Date(formattedEndDate)
}

function isWithinRoomCapacity(this: yup.TestContext) {
  const { adultCount, childrenCount } = this.parent as CAR_FORM_STATE_TYPE
  const travelerCount = adultCount + childrenCount

  return travelerCount <= MAXIMUM_BUNDLE_GUEST_COUNT
}

const searchFormSchema = yup.object().shape({
  childrenAges: yup.array().when(['childrenCount', 'tripType'], {
    is: (childrenCount: number, tripType: string) =>
      tripType.includes(STAY) && childrenCount > 0,
    then: schema =>
      schema.test({
        message: CHILD_AGE_REQUIRED,
        name: 'childrenAges',
        test: isChildrenAgeProvided
      }),
    otherwise: schema => schema.nullable()
  }),
  endDate: yup.string().when('tripType', {
    is: (tripType: string) => tripType === DRIVE,
    then: schema =>
      schema.required(INVALID_DATE).when('startDate', {
        is: (startDate: string) => startDate !== '',
        then: nestedSchema =>
          nestedSchema.test({
            message: DATE_RANGE_ERROR,
            name: 'endDate',
            test: isWithinDateRange
          }),
        otherwise: nestedSchema => nestedSchema.nullable()
      }),
    otherwise: schema => schema.nullable()
  }),
  endLocation: yup
    .object()
    .nullable()
    .when('oneWay', {
      is: true,
      then: schema => schema.nullable().required(DROPOFF_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    }),
  hotelEndDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.required(INVALID_DATES)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.required(INVALID_DATES)
    }),
  hotelStartDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema => schema.required(INVALID_DATES)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.required(INVALID_DATES)
    }),
  roomCount: yup
    .number()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType.includes(STAY),
      then: schema =>
        schema.test({
          message: BUNDLE_MAX_CAPACITY_ERROR,
          name: 'roomCount',
          test: isWithinRoomCapacity
        })
    }),
  startDate: yup
    .string()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType === DRIVE,
      then: schema => schema.required(INVALID_DATE)
    }),
  startLocation: yup
    .object()
    .nullable()
    .when('tripType', {
      is: (tripType: string) => tripType === DRIVE,
      then: schema => schema.nullable().required(PICKUP_LOCATION_REQUIRED)
    })
    .when('tripType', {
      is: (tripType: string) => tripType.includes(FLY),
      then: schema => schema.nullable().required(HOTEL_LOCATION_REQUIRED)
    })
})

export default searchFormSchema
