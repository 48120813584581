import React, { useEffect } from 'react'
import { Box, Button, Heading, Relative, Flex } from 'pcln-design-system'
import { parseISO } from 'date-fns/parseISO'
import { addDays } from 'date-fns/addDays'
import { UpcomingHotel, ExtendStayCard } from '@pcln/trip-activity-components'
import getHotelLink from '@/shared-utils/get-hotel-link'
import type { ExtendStayTrip } from '@/types'
import generateThumbnailURL from './utils'
import { fireViewTripClick } from '../CrossSell/ga4'
import fireExtendStayEvent from './ga4'

function ViewMyTrips() {
  return (
    <Button
      variation="subtle"
      size="small"
      onClick={() => {
        fireViewTripClick()
        window.open('/next-profile/trips')
      }}
      mb={[2, null, null, 0]}
    >
      View My Trips
    </Button>
  )
}

export default function ExtendStay({
  offerToken,
  travelStartDateTime,
  travelEndDateTime,
  cityName,
  stateName,
  hotelID,
  numRooms = 1,
  title
}: ExtendStayTrip) {
  useEffect(() => {
    fireExtendStayEvent('view_promotion')
  })
  const endDate = parseISO(travelEndDateTime)
  const image = generateThumbnailURL(hotelID)

  return (
    <Relative>
      <Flex justifyContent="space-between" px={1}>
        <Heading
          textStyle={['heading4', null, null, 'heading3']}
          color="text.heading"
          m={0}
          mb={3}
          as="h1"
        >
          Your Current Trip
        </Heading>
        <Box>
          <ViewMyTrips />
        </Box>
      </Flex>
      <Flex flexDirection={['column', null, null, 'row']}>
        <Box width={[1, null, null, 1 / 2]} px={1} mb={[2, null, null, 0]}>
          <UpcomingHotel
            hotelName={title}
            imageUrl={image}
            destination={`${cityName}, ${stateName}`}
            travelStartDate={travelStartDateTime}
            travelEndDate={travelEndDateTime}
            truncateHeading
            onClick={() => {
              fireViewTripClick()
              window.open(`/travel-itinerary/?offerToken=${offerToken}`)
            }}
          />
        </Box>
        <Box width={[1, null, null, 1 / 2]} px={1} mb={[2, null, null, 0]}>
          <ExtendStayCard
            cityName={cityName}
            onClick={() => {
              fireExtendStayEvent('select_promotion')
              window.open(
                getHotelLink(hotelID, endDate, addDays(endDate, 1), numRooms)
              )
            }}
          />
        </Box>
      </Flex>
    </Relative>
  )
}
