import useBootstrapData from './useBootstrapData'

export type ExtendStayBooking = {
  travelStartDateTime: string
  travelEndDateTime: string
  cityName: string
  stateName: string
  hotelID: string
  numRooms?: number
  title: string
  offerToken: string
}

export default function useExtendStayBooking() {
  const { mostRecentBooking, customer } = useBootstrapData()
  const extendStayTrip = mostRecentBooking?.current
  return extendStayTrip
    ? {
        offerToken: extendStayTrip.offerToken,
        travelStartDateTime: extendStayTrip.travelStartDateTime,
        travelEndDateTime: extendStayTrip.travelEndDateTime,
        cityName: extendStayTrip.cityName,
        stateName: extendStayTrip.stateName,
        hotelID: extendStayTrip.hotelID,
        numRooms: extendStayTrip.numRooms,
        title: extendStayTrip.title,
        customerFirstName: customer.firstName
      }
    : null
}
