import React from 'react'
import { Box, Text } from 'pcln-design-system'
import { handleSubmitForVIPDeals } from '../../Hotels/SearchForm/utils'
import Form from '../../Hotels/SearchForm/Form'
import { PromoDeal } from '../../Hotels/constants'

const modal = {
  contentTypeId: 'modal',
  url: '',
  internalName: 'modal',
  buttonText: 'Search VIP Deals',
  ariaLabel: 'Search VIP Deals',
  analyticsTracking: {
    contentTypeId: 'googleAnalyticsTracking',
    category: 'PCLN_HOME',
    action: 'Feature Box CTA',
    label: 'Search VIP Deals'
  },
  modalContent: {
    contentTypeId: 'moreInfo',
    body: (
      <Box p={[3, 0]}>
        <Box px={[0, 3]}>
          <Text
            color="text"
            fontSize={[3, null, null, 5]}
            fontWeight="bold"
            style={{ lineHeight: 1.25 }}
          >
            Search VIP Deals
          </Text>
          <Text
            color="text"
            fontSize={[1, null, null, 4]}
            style={{ lineHeight: 1.25 }}
            mb={3}
          >
            Save more as a Priceline VIP
          </Text>
          <Box pb={4}>
            <Form
              onSubmit={handleSubmitForVIPDeals}
              promoDeal={PromoDeal.VIP}
            />
          </Box>
        </Box>
      </Box>
    )
  }
} as const

const CONTENT = {
  contentTypeId: 'mediaCard',
  internalName: 'Media Card',
  overline: '',
  heading: '',
  subheading: "'Book Priceline VIP deals for your exclusive savings",
  media: {
    title: 'vip image',
    description: '',
    file: {
      url: 'https://s1.pclncdn.com/design-assets/home/promos/VIP.png?opto&auto=avif,webp',
      details: {
        size: 925398,
        image: {
          width: 1200,
          height: 400
        }
      },
      fileName: 'vip.png',
      contentType: 'image/jpeg'
    }
  },
  primaryCallToAction: modal
} as const

export default CONTENT
