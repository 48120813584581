const CONTENT = {
  contentTypeId: 'mediaCard',
  internalName: 'Media Card',
  overline: '',
  heading: '10% off coupon',
  subheading:
    'Book anything today and get 10% off your next Express Deal® Hotel',
  media: {
    title: 'Incredible Tile Promo',
    description: '',
    file: {
      url: 'https://s1.pclncdn.com/design-assets/home/promos/IncredibleTile.jpg',
      details: {
        size: 925398,
        image: {
          width: 1200,
          height: 400
        }
      },
      fileName: 'IncredibleTile.jpg',
      contentType: 'image/jpeg'
    }
  }
} as const

export default CONTENT
