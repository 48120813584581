import React from 'react'
import CONTENT from '@/constants/content'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import useBootstrapData from '@/hooks/useBootstrapData'
import { isIMediaCard } from '@/shared-utils/content-model-type-guards'
import MediaCardComponent from '../MediaCard/MediaCardComponent'
import mediaCardContentFallback from './content'

export default function VIP() {
  const {
    customer: { firstName }
  } = useBootstrapData()
  const promo = useContentfulVariation('leftPromoBox', isIMediaCard)
  const hasContentfulData = promo != null

  const headlineVIP = firstName
    ? CONTENT.homepage.vip.promo.headlineWithName.replace(
        '{firstName}',
        firstName
      )
    : CONTENT.homepage.vip.promo.headline

  const mediaCardContentUpdatedFallback = {
    ...mediaCardContentFallback,
    heading: headlineVIP
  } as const
  const mediaCardContent = hasContentfulData
    ? promo
    : mediaCardContentUpdatedFallback

  // MediaCardComponent requires contentful data to render. CONTENT is the fallback here.
  // The value for the body field is a React Element in the fallback content, hence the mismatch
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return <MediaCardComponent content={mediaCardContent} />
}
