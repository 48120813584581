import React, { useState } from 'react'
import { Box, Flex, FormField, Label, Select } from 'pcln-design-system'
import { Calendar, Clock, Cruises, Search } from 'pcln-icons'
import styled from 'styled-components'
import SearchFormButton from '@/components/SearchFormButton'
import useBootstrapData from '@/hooks/useBootstrapData'
import {
  locationOptions,
  cruiseLineOptions,
  durationOptions
} from '../constants'
import { createDateOptions, currentMonth, handleSubmit } from './utils'

const StyledSelect = styled(Select)`
  cursor: pointer;
  height: 56px;
`

const createOption = (
  option: {
    label: string
    value: string
    disabled?: boolean
    selected?: boolean
  },
  i: number
) => (
  <option key={`${option.label}${i}`} {...option}>
    {option.label}
  </option>
)

const locations = locationOptions.map(createOption)
const cruiseLines = cruiseLineOptions.map(createOption)
const dateOptions = createDateOptions(new Date()).map(createOption)
const durations = durationOptions.map(createOption)

function CruisesSearchForm() {
  const [location, setLocation] = useState('')
  const [cruiseLine, setCruiseLine] = useState('')
  const [startDate, setStartDate] = useState(currentMonth)
  const [duration, setDuration] = useState('')
  const { referral } = useBootstrapData()
  const todaysDate = new Date()
  return (
    <Box>
      <Box>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(
              {
                location,
                cruiseLine,
                startDate,
                duration
              },
              referral,
              todaysDate
            )
          }}
          aria-label="cruise search"
        >
          <Flex flexWrap="wrap" mx={-2} width={1}>
            <Box width={[1, 1 / 2]} px={2} mb={3}>
              <FormField>
                <Label autoHide={location === ''} htmlFor="location">
                  Destination (Any)
                </Label>
                <Search color="primary" />
                <StyledSelect
                  id="location"
                  name="location"
                  value={location}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setLocation(e.target.value)
                  }}
                >
                  {locations}
                </StyledSelect>
              </FormField>
            </Box>
            <Box width={[1, 1 / 2]} px={2} mb={3}>
              <FormField>
                <Label autoHide htmlFor="startDate">
                  Departing
                </Label>
                <Calendar color="primary" />
                <StyledSelect
                  id="startDate"
                  name="startDate"
                  value={startDate}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setStartDate(e.target.value)
                  }}
                >
                  {dateOptions}
                </StyledSelect>
              </FormField>
            </Box>
            <Box width={[1, 1 / 2, null, null, 1 / 3]} px={2} mb={3}>
              <FormField>
                <Label autoHide={duration === ''} htmlFor="duration">
                  Cruise Length (Any)
                </Label>
                <Clock color="primary" />
                <StyledSelect
                  id="duration"
                  name="duration"
                  value={duration}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setDuration(e.target.value)
                  }}
                >
                  {durations}
                </StyledSelect>
              </FormField>
            </Box>
            <Box width={[1, 1 / 2, null, null, 1 / 3]} px={2} mb={3}>
              <FormField>
                <Label autoHide={cruiseLine === ''} htmlFor="cruiseLine">
                  Cruise Line (Any)
                </Label>
                <Cruises color="primary" />
                <StyledSelect
                  id="cruiseLine"
                  name="cruiseLine"
                  value={cruiseLine}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setCruiseLine(e.target.value)
                  }}
                >
                  {cruiseLines}
                </StyledSelect>
              </FormField>
            </Box>
            <Box width={[1, null, null, null, 1 / 3]} px={2} ml="auto">
              <SearchFormButton buttonText="Book Now!" />
            </Box>
          </Flex>
        </form>
      </Box>
    </Box>
  )
}

export default CruisesSearchForm
