import React from 'react'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import MediaCardComponent from '@/components/Promos/MediaCard/MediaCardComponent'
import { isIMediaCard } from '@/shared-utils/content-model-type-guards'
import CONTENT from './content'

export default function IncredibleTile({
  contentFulFieldID
}: {
  contentFulFieldID: 'incredibleTile' | 'incredibleTileMediaCard'
}) {
  const promo = useContentfulVariation(contentFulFieldID, isIMediaCard)
  const hasContentfulData = promo != null
  const mediaCardContent = hasContentfulData ? promo : CONTENT

  return <MediaCardComponent content={mediaCardContent} />
}
