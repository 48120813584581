import React from 'react'
import {
  HotelsFill,
  HotelsOutline,
  FlightsFill,
  FlightsOutline,
  PackagesFill,
  PackagesOutline,
  CarsFill,
  CarsOutline,
  CriusesFill,
  CriusesOutline
} from '@pcln/brand'
import { ProductType } from '@/types'

type ProductIconType = {
  name: ProductType
  isActive: boolean
  size: string | number
}

const icons = {
  hotels: { outline: HotelsOutline, fill: HotelsFill },
  flights: { outline: FlightsOutline, fill: FlightsFill },
  vacations: { outline: PackagesOutline, fill: PackagesFill },
  cars: { outline: CarsOutline, fill: CarsFill },
  cruises: { outline: CriusesOutline, fill: CriusesFill }
} as const

function ProductIcon(props: ProductIconType) {
  const { name, isActive, ...iconProps } = props
  const IconType = isActive ? icons[name].fill : icons[name].outline
  return <IconType {...iconProps} />
}

export default ProductIcon
