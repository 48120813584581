import React from 'react'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import TripActivity from '../TripActivity'
import type { AbandonedSelection } from '../TripActivity/types'

export default function TripActivityModule({
  shouldUseSmallerHeading,
  defaultAbandonedSelection,
  isTripGroupingExperiment = false,
  hideAbandonedSelection = false
}: {
  shouldUseSmallerHeading?: boolean
  defaultAbandonedSelection?: AbandonedSelection | null
  isTripGroupingExperiment?: boolean
  hideAbandonedSelection?: boolean
}) {
  return (
    <TripActivity
      productType={['STAY', 'FLY', 'DRIVE']}
      analyticsCategory={ANALYTICS_CATEGORY_HOME}
      shouldUseSmallerHeading={shouldUseSmallerHeading}
      abandonedSelectionData={defaultAbandonedSelection}
      hideAbandonedSelection={hideAbandonedSelection}
      isTripGroupingExperiment={isTripGroupingExperiment}
    />
  )
}
