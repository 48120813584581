export const locationOptions = [
  { value: '', label: 'Destination (Any)', disabled: true, selected: true },
  { value: 'C', label: 'Caribbean' },
  { value: 'M', label: 'Mexico' },
  { value: 'BH', label: 'Bahamas' },
  { value: 'A', label: 'Alaska' },
  { value: 'E', label: 'Europe - All' },
  { value: 'H', label: 'Hawaii' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'NN', label: 'Canada/New England/NY' },
  { value: '_', disabled: true, label: '-------------' },
  { value: 'ALL', label: 'All Destinations' },
  { value: 'A', label: 'Alaska - All' },
  { value: 'AG', label: 'Alaska - Gulf of Alaska' },
  { value: 'AR', label: 'Alaska - Inside Passage' },
  { value: 'AB', label: 'Antarctica' },
  { value: 'UN', label: 'Australia/New Zealand' },
  { value: 'BH', label: 'Bahamas' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'NN', label: 'Canada/New England/NY' },
  { value: 'C', label: 'Caribbean - All' },
  { value: 'CE', label: 'Caribbean - Eastern' },
  { value: 'CS', label: 'Caribbean - Southern' },
  { value: 'CW', label: 'Caribbean - Western' },
  { value: 'CN', label: 'Cruise to Nowhere' },
  { value: 'E', label: 'Europe - All' },
  { value: 'EE', label: 'Europe - E. Mediterranean' },
  { value: 'R', label: 'Europe - Mediterranean - All' },
  { value: 'N', label: 'Europe - Northern' },
  { value: 'EW', label: 'Europe - W. Mediterranean' },
  { value: 'W', label: 'Europe - Western' },
  { value: 'O', label: 'Exotic - Asia/Africa/Other' },
  { value: 'H', label: 'Hawaii' },
  { value: 'M', label: 'Mexico' },
  { value: 'L', label: 'Pacific Coast' },
  { value: 'T', label: 'Panama Canal/C. America' },
  { value: 'S', label: 'South America' },
  { value: 'I', label: 'South Pacific - All' },
  { value: 'TH', label: 'South Pacific - Tahiti' },
  { value: 'ET', label: 'Transatlantic' },
  { value: 'NA', label: 'U.S. - All' },
  { value: 'RV', label: 'U.S. - American Rivers' },
  { value: 'WW', label: 'World Cruise' }
] as const

export const cruiseLineOptions = [
  { value: '', label: 'Cruise Line (Any)', disabled: true, selected: true },
  { value: 'ALL', label: 'All Cruise Lines' },
  { value: '92', label: 'AmaWaterways' },
  { value: '323', label: 'American Cruise Lines' },
  { value: '329', label: 'Avalon Waterways' },
  { value: '325', label: 'Azamara Cruises' },
  { value: '1', label: 'Carnival Cruise Lines' },
  { value: '11', label: 'Celebrity Cruises' },
  { value: '14', label: 'Costa Cruises' },
  { value: '369', label: 'Crystal Cruises' },
  { value: '18', label: 'Cunard' },
  { value: '20', label: 'Disney Cruise Line' },
  { value: '368', label: 'Explora Journeys' },
  { value: '24', label: 'Holland America Line' },
  { value: '338', label: 'Hurtigruten Cruises' },
  { value: '71', label: 'MSC Cruises' },
  { value: '34', label: 'Norwegian Cruise Line' },
  { value: '67', label: 'Oceania Cruises' },
  { value: '344', label: 'Ponant' },
  { value: '40', label: 'Princess Cruises' },
  { value: '41', label: 'Regent Seven Seas Cruises' },
  { value: '44', label: 'Royal Caribbean' },
  { value: '50', label: 'Silversea Cruises' },
  { value: '48', label: 'The Yachts of Seabourn' },
  { value: '66', label: 'Uniworld River Cruises' },
  { value: '354', label: 'Viking Ocean Cruises' },
  { value: '78', label: 'Viking River Cruises' },
  { value: '364', label: 'Virgin Voyages' },
  { value: '64', label: 'Windstar Cruises' }
] as const

export const durationOptions = [
  { value: '', label: 'Cruise Length (Any)', disabled: true, selected: true },
  { value: 'ALL', label: 'All' },
  { value: '1', label: '1-2 Nights' },
  { value: '2', label: '3-5 Nights' },
  { value: '3', label: '6-8 Nights' },
  { value: '4', label: '9-11 Nights' },
  { value: '5', label: '12+ Nights' }
] as const
