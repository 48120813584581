import { type Locale } from 'date-fns'
import { enCA, enGB, enUS, es, fr, pt, ptBR } from 'date-fns/locale'

export const locales: Record<string, Locale> = {
  'en-US': enUS,
  'en-GB': enGB,
  'en-CA': enCA,
  'pt-BR': ptBR,
  pt,
  es,
  fr
}

// In RC some locales contain country modifcations. example es-MX. But these may not exist in date-fns so return the language-only value.
const supportedLocale = (locale: string) => {
  if (locales[locale]) return locale
  if (locales[locale.substr(0, 2)]) return locale.substr(0, 2)
  return 'en-US'
}

export const validateLocale = (aliasLocale: string) => {
  if (!aliasLocale) return 'en-US'
  if (aliasLocale === 'en') return 'en-US'

  const matchedLocale = aliasLocale.match(/([a-z]{2})(?:-?([a-zA-Z]{2})?)/)
  if (!matchedLocale) {
    return 'en-US'
  }
  if (matchedLocale && matchedLocale.length && matchedLocale[2] === undefined) {
    return supportedLocale(matchedLocale[1])
  }
  return supportedLocale(
    matchedLocale[1].concat('-', matchedLocale[2].toUpperCase())
  )
}
