import React from 'react'
import { Box, Text } from 'pcln-design-system'
import { handleSubmitForPricebreakers } from '../../Hotels/SearchForm/utils'

import Form from '../../Hotels/SearchForm/Form'
import { PromoDeal } from '../../Hotels/constants'

const modal = {
  contentTypeId: 'modal',
  url: '',
  buttonText: 'Search Pricebreakers',
  testId: 'mobile-pricebreaker-button',
  ariaLabel: 'Pricebreakers',
  analyticsTracking: {
    contentTypeId: 'googleAnalyticsTracking',
    category: 'PCLN_HOME',
    action: 'Feature Box CTA',
    label: 'Search VIP Deals'
  },
  modalContent: {
    contentTypeId: 'modalContent',
    body: (
      <Box p={[3, 0]}>
        <Box px={[0, 3]}>
          <Text
            color="text"
            fontSize={[3, null, null, 5]}
            fontWeight="bold"
            style={{ lineHeight: 1.25 }}
          >
            Search Pricebreakers
          </Text>
          <Text
            color="text"
            fontSize={[1, null, null, 4]}
            style={{ lineHeight: 1.25 }}
            mb={3}
          >
            Our highest rated hotels - up to 50% off retail
          </Text>
          <Box pb={4}>
            <Form
              onSubmit={handleSubmitForPricebreakers}
              promoDeal={PromoDeal.COLLECTIONS}
            />
          </Box>
        </Box>
      </Box>
    )
  }
} as const

const CONTENT = {
  contentTypeId: 'mediaCard',
  internalName: 'Media Card',
  overline: '',
  heading: 'Save up to 50% off hotels with Pricebreakers',
  subheading:
    'See 3 popular hotels at a heavily discounted price. See 3 popular hotels at a heavily discounted price. See 3 popular hotels at a heavily discounted price.',
  media: {
    title: 'pricebreakers image',
    description: '',
    file: {
      url: 'https://s1.pclncdn.com/design-assets/home/promos/Pricebreakers.jpg?opto&auto=avif,webp',
      details: {
        size: 925398,
        image: {
          width: 1200,
          height: 400
        }
      },
      fileName: 'Pricebreakers.jpg',
      contentType: 'image/jpeg'
    }
  },
  primaryCallToAction: modal
} as const

export default CONTENT
