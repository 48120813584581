import { useState } from 'react'
import useSEMSearchHeadline from '@/hooks/useSEMSearchHeadline'
import { GREETING_DESKTOP, vipHeading } from '@/constants/content'
import useCityNameInFormHeadlineForSEM from '@/hooks/useCityNameInFormHeadlineForSEM'
import type { HeadlineType, LOCATION_SEARCH_TYPE } from '@/types'
import useBootstrapData from '@/hooks/useBootstrapData'

type SearchFormProductHeadlineType = {
  hotels: HeadlineType
  flights: HeadlineType
  vacations: HeadlineType
  cars: HeadlineType
  cruises: HeadlineType
}

export default function useFormatHotelHeadline(
  values: SearchFormProductHeadlineType
) {
  const {
    customer: { firstName },
    signInStatus: { signedIn }
  } = useBootstrapData()

  const [location, setLocation] = useState<LOCATION_SEARCH_TYPE | undefined>()

  const SEMHeadline = useSEMSearchHeadline()
  const prePopHeadline = useCityNameInFormHeadlineForSEM(location)
  const headlineWithName = firstName
    ? vipHeading.headlineWithName(firstName)
    : GREETING_DESKTOP
  const defaultHeadline = signedIn ? headlineWithName : values.hotels.headline
  const hotelHeadline = prePopHeadline || SEMHeadline || defaultHeadline

  return { hotelHeadline, setLocation }
}
