import { useQuery } from '@tanstack/react-query'
import { parseISO } from 'date-fns/parseISO'
import { isToday } from 'date-fns/isToday'
import { isAfter } from 'date-fns/isAfter'
import analytics from '@/shared-utils/analytics'
import type {
  TripGroupingApiResponse,
  TripGrouping
} from '@/components/TripGrouping/types'
import fetchTripGroupings from '@/shared-utils/fetchTripGroupings'
import { getFirstValidAbandonedSelection } from '../TripActivity/hooks/useAbandonedSelections'

function isTripISODateValid(tripDate: string) {
  const parseTripDate = parseISO(tripDate)

  return isToday(parseTripDate) || isAfter(parseTripDate, new Date())
}

function getFirstValidTripGrouping(
  groupings: ReadonlyArray<TripGroupingApiResponse>
) {
  const tripGroup = groupings.find(grouping =>
    isTripISODateValid(grouping.bookingActivityData[0]?.travelStartDateTime)
  )

  // in case of multiple bookings, show first booking in group
  const tripGroupingBooking = tripGroup?.bookingActivityData?.[0] ?? null

  const tripGroupingAbandonedSelection = getFirstValidAbandonedSelection(
    tripGroup?.selectionActivityData ?? []
  )

  return { tripGroupingBooking, tripGroupingAbandonedSelection }
}

export default function useTripGroupings(
  cguid: string,
  appName: string,
  appVersion: string,
  signedIn: boolean,
  shouldFetchTripGroupings: boolean
) {
  const { data = null, error } = useQuery<TripGrouping>({
    queryKey: [cguid, appName, appVersion, signedIn, 'tripGroupings'],
    queryFn: async () => {
      const selections = await fetchTripGroupings(cguid, appName, appVersion)

      const { tripGroupingBooking, tripGroupingAbandonedSelection } =
        getFirstValidTripGrouping(selections.tripGroupings)

      const defaultAbandonedSelection = getFirstValidAbandonedSelection(
        selections.abandonedSelections
      )

      return {
        tripGroupingBooking,
        tripGroupingAbandonedSelection,
        defaultAbandonedSelection
      }
    },
    staleTime: Infinity,
    enabled: shouldFetchTripGroupings,
    retry: false // no retry on error
  })

  if (error) {
    analytics.logError({
      message: 'Error thrown from useQuery',
      erorr: JSON.stringify(error),
      service: 'fetchTripGroupings'
    })
  }

  return data
}
