import React from 'react'
import { Box, Hide } from 'pcln-design-system'
import { type Booking } from '@/components/CrossSell/types'
import useExtendStayBooking, {
  type ExtendStayBooking
} from '@/hooks/useExtendStayBooking'
import useMostRecentFutureBooking from '@/hooks/useMostRecentFutureBooking'
import ExtendStay from '@/components/ExtendStay'
import CrossSell from '@/components/CrossSell/CrossSell'
import useBootstrapData from '@/hooks/useBootstrapData'
import TripGrouping from '@/components/TripGrouping'
import useSeti from '@/hooks/useSeti'
import ConditionalRender, {
  Experiment,
  Original
} from '@/components/ConditionalRender'
import TripActivityModule from '../TripActivityModule'
import useTripGroupings from '../../TripGrouping/useTripGroupings'

function UpcomingTrip({
  mostRecentFutureBooking,
  extendData
}: {
  mostRecentFutureBooking?: Booking | null
  extendData: ExtendStayBooking | null
}) {
  return (
    (extendData || mostRecentFutureBooking) && (
      <Hide md mb={4}>
        {extendData ? (
          <ExtendStay {...extendData} />
        ) : (
          mostRecentFutureBooking && (
            <CrossSell booking={mostRecentFutureBooking} />
          )
        )}
      </Hide>
    )
  )
}

export default function TripDisplayOrder() {
  const { mostRecentFutureBooking } = useMostRecentFutureBooking()
  const extendData = useExtendStayBooking()

  const {
    webstats: { clientGUID },
    appName,
    appVersion,
    signInStatus: { signedIn }
  } = useBootstrapData()

  const shouldUseSmallerHeading = Boolean(mostRecentFutureBooking || extendData)

  const isTripGroupingExperiment =
    useSeti('HP_PUWYLO_TRIPGROUPING', signedIn && !extendData) === 'VARIANT'

  const shouldFetchTripGroupings =
    isTripGroupingExperiment && signedIn && !extendData

  const {
    tripGroupingBooking = null,
    tripGroupingAbandonedSelection = null,
    defaultAbandonedSelection = null
  } = useTripGroupings(
    clientGUID,
    appName,
    appVersion,
    signedIn,
    shouldFetchTripGroupings
  ) || {}

  // experiment only runs when no Extend Your Stay
  if (signedIn && !extendData) {
    return (
      <Box mb={[0, null, 1]}>
        <ConditionalRender experimentCode="HP_PUWYLO_TRIPGROUPING">
          <Original>
            <UpcomingTrip
              extendData={null}
              mostRecentFutureBooking={mostRecentFutureBooking}
            />
            <TripActivityModule
              shouldUseSmallerHeading={shouldUseSmallerHeading}
              isTripGroupingExperiment={isTripGroupingExperiment}
            />
          </Original>
          <Experiment>
            <TripGrouping
              tripGroupingBooking={tripGroupingBooking}
              tripGroupingAbandonedSelection={tripGroupingAbandonedSelection}
              defaultAbandonedSelection={defaultAbandonedSelection}
            />
          </Experiment>
        </ConditionalRender>
      </Box>
    )
  }

  return (
    <Box mb={[0, null, 1]}>
      <UpcomingTrip
        extendData={extendData}
        mostRecentFutureBooking={mostRecentFutureBooking}
      />
      <TripActivityModule shouldUseSmallerHeading={shouldUseSmallerHeading} />
    </Box>
  )
}
