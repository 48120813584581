import useBootstrapData from './useBootstrapData'

const imageFolder = 'https://s1.pclncdn.com/design-assets/hero/city/'

const citiesIdsWithImages = [
  3000000247, 3000000983, 3000001284, 3000001349, 3000001372, 3000001401,
  3000001438, 3000001468, 3000001750, 3000001947, 3000002028, 3000002085,
  3000002228, 3000002241, 3000002244, 3000002250, 3000002557, 3000002573,
  3000003032, 3000003192, 3000003204, 3000003249, 3000003263, 3000003311,
  3000003349, 3000003394, 3000003430, 3000003431, 3000003496, 3000003899,
  3000004031, 3000004976, 3000005381, 3000006556, 3000007853, 3000007866,
  3000008192, 3000008434, 3000008602, 3000008983, 3000010039, 3000010974,
  3000011692, 3000011975, 3000012799, 3000012874, 3000012928, 3000012998,
  3000013221, 3000014046, 3000014119, 3000014521, 3000014999, 3000015284,
  3000015300, 3000015330, 3000016152, 3000016793, 3000016799, 3000016809,
  3000017780, 3000017897, 3000018178, 3000018774, 3000019204, 3000019211,
  3000019687, 3000019699, 3000019754, 3000019836, 3000020244, 3000020394,
  3000020556, 3000020605, 3000020633, 3000020669, 3000020830, 3000020845,
  3000020856, 3000021082, 3000021147, 3000021192, 3000021212, 3000021312,
  3000021337, 3000021763, 3000022148, 3000022704, 3000022821, 3000022847,
  3000023414, 3000023431, 3000023843, 3000023880, 3000024967, 3000031041,
  3000033184, 3000033452, 3000000013, 3000000412, 3000000443, 3000000511,
  3000000514, 3000000544, 3000000859, 3000000867, 3000000936, 3000001121,
  3000001294, 3000001310, 3000001330, 3000001374, 3000001391, 3000001409,
  3000001489, 3000001526, 3000001562, 3000001563, 3000001624, 3000001839,
  3000001942, 3000002089, 3000002117, 3000002237, 3000002269, 3000002270,
  3000002273, 3000002277, 3000002374, 3000002512, 3000002591, 3000002738,
  3000002914, 3000003025, 3000003042, 3000003075, 3000003106, 3000003142,
  3000003143, 3000003147, 3000003159, 3000003165, 3000003167, 3000003178,
  3000003195, 3000003196, 3000003198, 3000003232, 3000003255, 3000003260,
  3000003299, 3000003307, 3000003324, 3000003366, 3000003369, 3000003384,
  3000003397, 3000003405, 3000003441, 3000003451, 3000003495, 3000003499,
  3000003627, 3000003968, 3000004027, 3000004038, 3000004060, 3000004115,
  3000004219, 3000004284, 3000004304, 3000004320, 3000004819, 3000004996,
  3000005056, 3000005106, 3000005172, 3000006345, 3000006383, 3000006459,
  3000006466, 3000006483, 3000006530, 3000006647, 3000006689, 3000006796,
  3000006817, 3000007481, 3000007565, 3000008361, 3000008506, 3000008619,
  3000008975, 3000009098, 3000009206, 3000009340, 3000009344, 3000009345,
  3000009462, 3000009626, 3000009772, 3000009863, 3000009942, 3000010004,
  3000010083, 3000010151, 3000010449, 3000010704, 3000011097, 3000011126,
  3000011364, 3000012014, 3000012156, 3000012270, 3000012349, 3000012490,
  3000012499, 3000012507, 3000012663, 3000013149, 3000013383, 3000013388,
  3000014407, 3000014456, 3000014705, 3000014810, 3000015206, 3000015277,
  3000015285, 3000015289, 3000015470, 3000015474, 3000016162, 3000016338,
  3000016474, 3000016662, 3000016689, 3000016773, 3000016835, 3000016853,
  3000017052, 3000017059, 3000017209, 3000017287, 3000017358, 3000017372,
  3000017961, 3000017974, 3000017975, 3000018046, 3000018119, 3000018156,
  3000018202, 3000018209, 3000018798, 3000018900, 3000019617, 3000019626,
  3000019733, 3000019766, 3000019831, 3000019878, 3000019910, 3000020030,
  3000020217, 3000020489, 3000020632, 3000020813, 3000021054, 3000021197,
  3000021455, 3000021596, 3000021660, 3000021770, 3000021825, 3000021931,
  3000021939, 3000022021, 3000022057, 3000022102, 3000022119, 3000022125,
  3000022136, 3000022145, 3000022190, 3000022201, 3000022300, 3000022428,
  3000022476, 3000022627, 3000022633, 3000022711, 3000023134, 3000023742,
  3000024139, 3000024768, 3000024950, 3000025013, 3000025100, 3000025150,
  3000030122, 3000030632, 3000030844, 3000030999, 3000031000, 3000031085,
  3000031161, 3000032664, 3000033051, 3000033144, 3000033237, 3000033518,
  3000035653, 3000035763, 3000035812, 3000035821, 3000035822, 3000035823,
  3000035824, 3000035825, 3000035826, 3000035827, 3000035833, 3000035879,
  3000035880, 3000035882, 3000035886, 3000035887, 3000035889, 3000035890,
  3000035894, 3000035895, 3000035919, 3000035932, 3000035938, 3000040031,
  3000040045, 3000060010, 3000060012, 3000060016, 3000060055, 3000060262,
  3000060600, 3000060955, 3000061777, 3000061779, 3000061781, 3000061787,
  3000062401, 3000062417, 3000070000, 3000070002, 3000080002, 3000090052,
  3000090246, 3000090568, 3000090588, 3000092223, 3000096245, 3000096321,
  3000097071, 3000097769, 5000016489, 5000024971, 5000025185, 5000090279,
  5000457862, 5000461998, 5000494493, 5000495528, 5000495791, 5000503659,
  5000503670
]

export default function useMobileHeroCityImage() {
  const { prePopData } = useBootstrapData()
  const endLocation = prePopData?.hotels?.endLocation

  const hasCity = !!endLocation?.cityName && endLocation?.type === 'CITY'
  const cityId = Number(endLocation && endLocation.cityID)

  const hasCityImage = hasCity && citiesIdsWithImages.includes(cityId)
  return hasCityImage && endLocation.cityID
    ? {
        src: `${imageFolder}${endLocation.cityID}.jpg?opto&auto=avif,webp&width=500&fit=bounds,safe`,
        altName: endLocation?.cityName || 'City image'
      }
    : null
}
