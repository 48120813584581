import {
  CRUISE_PRODUCT_TYPE,
  FLIGHT_PRODUCT_TYPE,
  HOTEL_PRODUCT_TYPE,
  RENTAL_CAR_PRODUCT_TYPE,
  VACATIONS_PRODUCT_TYPE
} from '@/constants/products'
import { ProductType, QueryString } from '@/types'

const validProductTypes = [
  CRUISE_PRODUCT_TYPE,
  FLIGHT_PRODUCT_TYPE,
  HOTEL_PRODUCT_TYPE,
  RENTAL_CAR_PRODUCT_TYPE,
  VACATIONS_PRODUCT_TYPE
] as const

function isValidQueryParamForProductType(
  queryParam: string
): queryParam is ProductType {
  return validProductTypes.includes(
    queryParam as (typeof validProductTypes)[number]
  )
}

export default function getCurrentTab(query: QueryString): ProductType {
  return Object.keys(query).length &&
    typeof query.tab === 'string' &&
    isValidQueryParamForProductType(query.tab)
    ? query.tab
    : 'hotels'
}
