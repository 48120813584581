const FASTLY_VALUES = {
  opto: true,
  crop: '261:261,offset-y100',
  auto: 'avif,webp'
}

function toQueryString(
  params: Record<string, string | boolean>, // typeof FASTLY_VALUES
  encodeFields = false
) {
  const queryStrings = Object.keys(params).map(key => {
    const encodeValue = encodeFields
      ? encodeURIComponent(params[key])
      : params[key]
    return `${key}=${encodeValue}`
  })
  return queryStrings.join('&')
}

function generateHotelCoverPhotoUrl(hotelID = '') {
  if (!hotelID) {
    return ''
  }
  const castedPropertyID: number = parseInt(hotelID, 10)
  const fPath = Math.floor(castedPropertyID / 100000)
  const sPath = Math.floor(castedPropertyID / 10000 - fPath * 10)
  const tPath = Math.floor(castedPropertyID / 1000 - (fPath * 10 + sPath) * 10)

  return `https://mobileimg.pclncdn.com/htlimg/master/${fPath}/${sPath}/${tPath}/${hotelID}/master_${hotelID}`
}

function generateThumbnailURL(hotelID = '') {
  if (!hotelID) {
    return ''
  }

  const masterImageURL = generateHotelCoverPhotoUrl(hotelID)
  const fastlyQS = toQueryString(FASTLY_VALUES)
  return `${masterImageURL}?${fastlyQS}`
}

export default generateThumbnailURL
