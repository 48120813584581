import { useRouter } from 'next/router'
import { AMENITIES } from '@/components/SearchForm/constants'

const imageFolder = 'https://s1.pclncdn.com/design-assets/hero/amenity/'

export default function useMobileHeroAmenityImage() {
  const { query } = useRouter()
  const { SEMSearch, SEMSEARCH } = query
  const semSearchParam = (
    (SEMSearch || SEMSEARCH) as string | undefined
  )?.toUpperCase() as keyof typeof AMENITIES
  const isSemSearchAmenity =
    semSearchParam !== undefined && AMENITIES[semSearchParam] !== undefined
  return isSemSearchAmenity
    ? {
        src: `${imageFolder}${semSearchParam}.jpg?opto&auto=avif,webp&crop=3:1`,
        altName: semSearchParam
      }
    : null
}
