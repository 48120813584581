import analytics from '@/shared-utils/analytics'

export default function fireExtendStayEvent(
  event: 'view_promotion' | 'select_promotion'
) {
  analytics.fireGA4Event({
    event,
    attributes: {
      type: 'recommendation',
      page_name: 'homepage',
      product_name: 'hotel',
      promotion_name: 'hotel',
      creative_name: 'extend_stay'
    }
  })
}
