import analytics from '@/shared-utils/analytics'
import { GA4ProductMap, type GA4ProductType } from '@/ga4types'
import { Product, ProductType } from './types'

const fireGA4EventWithCommonAttributes = (
  event: 'select_promotion' | 'view_promotion',
  additionalAttributes: {
    product_name: GA4ProductType
    promotion_name?: GA4ProductType
    ecommerce?: {
      transaction_id: string
    }
  }
) => {
  analytics.fireGA4Event({
    event,
    attributes: {
      type: 'xsell',
      page_name: 'homepage',
      ...additionalAttributes
    }
  })
}
export const getGA4ProductTypeById = (productId: 1 | 5 | 8) => {
  // eslint-disable-next-line default-case
  switch (productId) {
    case 1:
      return Product.Flight
    case 5:
      return Product.Hotel
    case 8:
      return Product.Car
  }
}
export const fireViewPromotionXsell = (
  productName: 1 | 5 | 8,
  offerId?: string
) => {
  fireGA4EventWithCommonAttributes('view_promotion', {
    product_name: GA4ProductMap[productName],
    ...(offerId ? { ecommerce: { transaction_id: offerId } } : null)
  })
}

export const fireViewTripClick = () => {
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      link_name: 'view_my_trip',
      page_name: 'homepage'
    }
  })
}

export const fireViewPromotionClick = (
  product: ProductType,
  xSellProduct: ProductType,
  offerId: string
) => {
  fireGA4EventWithCommonAttributes('select_promotion', {
    product_name: GA4ProductMap[product],
    promotion_name: GA4ProductMap[xSellProduct],
    ecommerce: {
      transaction_id: offerId
    }
  })
}

export const fireGetHelpClick = (product: ProductType) => {
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      type: 'penny',
      link_name: 'get_help',
      page_name: 'homepage',
      product_name: GA4ProductMap[product]
    }
  })
}
