import { addDays } from 'date-fns/addDays'
import { parseISO } from 'date-fns/parseISO'
import { format } from 'date-fns/format'
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays'
import { Booking, Product } from './types'
import type { BookedItemApiResponse } from '../TripGrouping/types'
import {
  isStayBooking,
  isFlyBooking,
  isDriveBooking
} from '../TripGrouping/utils'

export function buildContent(booking: Booking) {
  const startDate = parseISO(booking.travelStartDateTime)
  const endDate = parseISO(booking.travelEndDateTime)

  const hotelsXSellUrl = (destination: string, start: Date, end: Date) =>
    `/relax/in/${destination}/from/${format(start, 'yyyyMMdd')}/to/${format(
      end,
      'yyyyMMdd'
    )}/rooms/1`

  const driveXSellUrl = (
    destinationCity: string,
    destinationCountryCode: string | null,
    destinationProvinceCode: string,
    start: Date,
    end: Date
  ) => {
    const destinationCode: string = `,${
      destinationProvinceCode || destinationCountryCode || ''
    }`.replace(/^,$/, '')
    return `/drive/search/r/${destinationCity}${destinationCode}/${destinationCity}${destinationCode}/${format(
      start,
      'yyyyMMdd'
    )}-12:00/${format(end, 'yyyyMMdd')}-12:00/list`
  }

  switch (booking.productId) {
    case Product.Hotel: {
      return {
        xSellTargetId: Product.Car,
        xSellUrl: driveXSellUrl(
          booking.destinationCity,
          booking.destinationCountryCode,
          booking.destinationProvinceCode,
          startDate,
          endDate
        )
      }
    }
    case Product.Flight: {
      const destination =
        booking.destinationCity || booking.destinationLocationCode

      const isSameDay = differenceInCalendarDays(endDate, startDate) < 1
      return {
        xSellTargetId: Product.Hotel,
        xSellUrl: hotelsXSellUrl(
          destination,
          startDate,
          isSameDay ? addDays(endDate, 1) : endDate
        )
      }
    }
    case Product.Car: {
      return {
        xSellTargetId: Product.Hotel,
        xSellUrl: hotelsXSellUrl(booking.destinationCity, startDate, endDate)
      }
    }
    // no default
  }
}

export function buildContentExperiment(booking: BookedItemApiResponse) {
  const startDate = parseISO(booking.travelStartDateTime)
  const endDate = parseISO(booking.travelEndDateTime)

  const hotelsXSellUrl = (destination: string, start: Date, end: Date) =>
    `/relax/in/${destination}/from/${format(start, 'yyyyMMdd')}/to/${format(
      end,
      'yyyyMMdd'
    )}/rooms/1`

  if (isStayBooking(booking)) {
    return {
      xSellTargetId: Product.Car,
      xSellUrl: `/drive/search/r/${booking.cityName},${booking.countryCode}/${
        booking.cityName
      },${booking.countryCode}/${format(startDate, 'yyyyMMdd')}-12:00/${format(
        endDate,
        'yyyyMMdd'
      )}-12:00/list`
    }
  }

  if (isFlyBooking(booking)) {
    const isSameDay = differenceInCalendarDays(endDate, startDate) < 1
    return {
      xSellTargetId: Product.Hotel,
      xSellUrl: hotelsXSellUrl(
        booking.destinationAirportCode,
        startDate,
        isSameDay ? addDays(endDate, 1) : endDate
      )
    }
  }

  if (isDriveBooking(booking)) {
    return {
      xSellTargetId: Product.Hotel,
      xSellUrl: hotelsXSellUrl(
        booking.pickupLocationCityName,
        startDate,
        endDate
      )
    }
  }

  return {}
}
