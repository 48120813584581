import { format } from 'date-fns/format'
import { add } from 'date-fns/add'
import times from 'lodash/times'
import qs from 'query-string'
import { Referral } from '@/types'
import fireOnSubmitEvent from '../ga4'

export const trackCruisesSubmit = (values: {
  location: string
  cruiseLine: string
  startDate: string
  duration: string
}) => {
  const data = [
    `Destination_${values.location}`,
    `Cruise line_${values.cruiseLine}`,
    `Departure Date ${values.startDate}`,
    `Cruise Length_${values.duration}`
  ].join('|')
  fireOnSubmitEvent(data)
}

export const handleSubmit = (
  data: {
    location: string
    cruiseLine: string
    startDate: string
    duration: string
  },
  referral: Referral,
  todaysDate: Date
) => {
  const { location, cruiseLine, startDate, duration } = data
  const startDateTime = new Date(startDate)
  const today = todaysDate

  if (
    startDateTime.getFullYear() === today.getFullYear() &&
    startDateTime.getMonth() === today.getMonth() &&
    today.getDate() >= 15
  ) {
    // Increment month by 1 if today is the 15th or later and startDate is in the current month
    startDateTime.setMonth(startDateTime.getMonth() + 1)
  }

  const correctedDate = `${(startDateTime.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/01/${startDateTime.getFullYear()}`

  // Replace '|' with an empty string in referral.clickId as wth site does not handle it.
  const sanitizedClickId = referral?.clickId?.replace(/\|/g, '')
  const sanitizedRefId = referral?.id?.replace(/\|/g, '')
  const query = qs.stringify({
    places: location,
    operator: cruiseLine,
    Month: correctedDate,
    days: duration,
    sort_by: 7,
    refId: sanitizedRefId,
    refClickId: sanitizedClickId,
    utm_medium: 'partner_site_hp_sw',
    utm_source: 'pcln-hp_sw',
    utm_campaign: 'integrated',
    utm_content: 'hp_search'
  })

  trackCruisesSubmit(data)

  window.open(`//cruises.priceline.com/results.do?${query}`)
}

export const currentMonth = format(new Date(), 'MM/1/yyyy')

export const createDateOptions = (date: Date) => {
  const options: Array<{ value: string; label: string }> = []
  const parseOptions = (i: number) => {
    const newMonth = add(date, {
      months: i
    })
    const monthDate = format(newMonth, 'MM/1/yyyy')
    options.push({
      value: monthDate,
      label: format(newMonth, 'MMMM yyyy')
    })
  }

  times(24, parseOptions)
  return options
}
