import { EnumLiteralsOf } from '@/types'

export const Product = {
  Flight: 1,
  Hotel: 5,
  Car: 8
} as const

export type ProductType = EnumLiteralsOf<typeof Product>

type Product = 'hotel' | 'flight' | 'car'

type CommonBookingProperties = {
  offerId: string
  offerToken: string
  secureToken: string
  productId: ProductType
  travelStartDateTime: string
  travelEndDateTime: string
  originLocationCode: string
  destinationLocationCode: string
  destinationCity: string
  destinationProvinceCode: string
  // Sometimes given as null - needs BE fix
  destinationCountryCode: string | null
  utcCheckInDateTime: string
  hotelId: string
  hotelNumRooms: number
  hotelName: string
}

type Pricing = {
  currencySymbol: string
  disclaimer: string
  price: string
  priceDescription: string
}

type Partner = {
  name?: string
  url?: string
}

type VehicleFeatures = {
  bagCapacity: number
  isAirConditioned: boolean
  isUnlimitedMileage: boolean
  mileageDistance: string
  mileageUnit: string
  peopleCapacity: number
}

type Rate = {
  currencyCode: string
  currencySymbol: string
  dailyPrice: string
  isFreeCancellation: boolean
  isPrepay: boolean
  savingsPriceTotal: string
  strikeThroughPrice: string
  totalPrice: string
}

export type CarListing = {
  offerToken: string
  bannerType: string
  carExample: string
  carImageUrl: string
  href: string
  id: string
  isCarExampleExact: boolean
  options: string[]
  partner: Partner
  pricing: Pricing
  rate: Rate
  checkoutUrl: string
  vehicleFeatures: VehicleFeatures
}

export type HotelListing = {
  offerToken: string
  currency: string
  currencySymbol: string
  currentPrice: number
  guestRating: number
  guestReviewCount: number
  hotelId: string
  href: string
  imageUrl: string
  location: string
  name: string
  originalPrice: number
  savingPercentage: number | null
  starRating: number
}

export type HotelBooking = { productId: typeof Product.Hotel } & Omit<
  CommonBookingProperties,
  'originLocationCode'
>
export type FlightBooking = { productId: typeof Product.Flight } & Omit<
  CommonBookingProperties,
  | 'utcCheckInDateTime'
  | 'destinationCountryCode'
  | 'destinationProvinceCode'
  | 'hotelName'
  | 'hotelId'
  | 'hotelNumRooms'
>
export type CarBooking = { productId: typeof Product.Car } & Omit<
  CommonBookingProperties,
  | 'originLocationCode'
  | 'destinationLocationCode'
  | 'destinationCountryCode'
  | 'hotelName'
  | 'hotelId'
  | 'hotelNumRooms'
>
export type Booking = HotelBooking | FlightBooking | CarBooking
