import { format } from 'date-fns/format'

export default function getHotelLink(
  destination: string,
  start: number | Date,
  end: number | Date,
  numRooms: number
) {
  return `/relax/at/${destination}/from/${format(
    start,
    'yyyyMMdd'
  )}/to/${format(end, 'yyyyMMdd')}/rooms/${numRooms}?iscalendaropen=true`
}
