import React from 'react'
import CrossSell from '@/components/CrossSell/CrossSell'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import AbandonedSelection from '@/components/TripActivity/AbandonedSelection'
import {
  HighlightedFlex,
  HotelsOutline,
  FlightsOutline,
  CarsOutline
} from '@pcln/brand'
import { CarsBrand, ChevronRight, HotelsBrand } from 'pcln-icons'
import styled from 'styled-components'
import {
  Box,
  Text,
  getPaletteColor,
  GenericBanner,
  Flex,
  Link
} from 'pcln-design-system'

import type {
  TripGrouping as TripGroupingProps,
  BookedItemApiResponse,
  AbandonedSelection as AbandonedItem
} from './types'
import TripActivityModule from '../Home/TripActivityModule'
import { buildTripGroupingContent } from './utils'

const FlexWithTopBorder = styled(Flex)`
  border-top: 4px solid ${getPaletteColor('primary.base')};
  height: 100%;
`

const RightAlignedChevronRight = styled(ChevronRight)`
  margin-left: auto;
`

function ProductIcon({
  productType
}: {
  productType: 'STAY' | 'FLY' | 'DRIVE'
}) {
  const ProductIconMap = {
    STAY: HotelsOutline,
    FLY: FlightsOutline,
    DRIVE: CarsOutline
  }

  const IconComponent = ProductIconMap[productType]

  return (
    <Flex px="12px" py="6px" borderRadius="full" bg="primary.light">
      <Box mt="6px">
        <IconComponent size="32px" />
      </Box>
    </Flex>
  )
}

function BannerIcon({ productType }: { productType: 'hotels' | 'cars' }) {
  const iconMap = {
    hotels: <HotelsBrand />,
    cars: <CarsBrand />
  }
  return iconMap[productType]
}

function getBannerProduct(abandonedSelection: AbandonedItem) {
  switch (abandonedSelection.productType) {
    case 'hotels':
      return 'hotel'
    case 'cars':
      return 'rental car'
    // no default
  }
}

function GroupedTrip({
  booking,
  abandonedSelection
}: {
  booking: BookedItemApiResponse
  abandonedSelection: AbandonedItem
}) {
  const formattedBooking = buildTripGroupingContent(booking)
  return (
    formattedBooking && (
      <HighlightedFlex
        shadowOffsetX={24}
        shadowOffsetY={16}
        maxWidth="1216px"
        aria-label="Your trip is coming up!"
        flexDirection={['column', null, null, null, 'row']}
        justifyContent="space-between"
      >
        <FlexWithTopBorder
          flexDirection="column"
          width={[1, null, null, null, 1 / 3, 1 / 4]}
          p={3}
          mr={[0, null, 3]}
          borderRadius="xl"
          boxShadowSize="sm"
          justifyContent="center"
          color="background.lightest"
        >
          <Text
            color="text.heading"
            textStyle={['heading3', null, 'heading2']}
            mb={2}
          >
            Your trip is coming up!
          </Text>
          <Text color="text.light" textStyle="subheading5" mb={3}>
            {formattedBooking.headline}
          </Text>
          <Flex mb={3} alignItems="center">
            <ProductIcon productType={booking.productType} />
            <Box ml={3}>
              <Text mb={1} textStyle="heading4">
                {formattedBooking.locationCopy}
              </Text>
              <Text textStyle="subheading6">
                {formattedBooking.subHeadline}
              </Text>
            </Box>
          </Flex>

          <Link
            href={formattedBooking?.itineraryUrl}
            target="_blank"
            variation="fill"
          >
            View Trip
          </Link>
        </FlexWithTopBorder>
        <Flex
          flexDirection="column"
          justifyContent="flex-end"
          width={[1, null, null, null, 2 / 3, 3 / 4]}
          px={3}
          pb={1}
          mt={[4, null, null, null, 0]}
        >
          <Text
            color="text.heading"
            textStyle={['heading4', null, 'heading3']}
            mb={3}
          >
            Continue planning your trip to {formattedBooking.cityName}
          </Text>
          <Box mb={3}>
            <AbandonedSelection
              useSmallerView
              isTripGroupedSelection
              abandonedSelection={abandonedSelection}
              analyticsCategory={ANALYTICS_CATEGORY_HOME}
            />
          </Box>
          <GenericBanner
            py={2}
            px={3}
            borderRadius="lg"
            alignItems="center"
            justifyContent="flex-start"
            colorScheme="primaryDark"
            iconLeft={
              <BannerIcon productType={abandonedSelection.productType} />
            }
            iconRight={<RightAlignedChevronRight />}
            fontSize={['12px', null, '14px']}
            URLProps={{
              href: abandonedSelection.genericListingsUrl,
              target: '_blank'
            }}
            text={
              <Text>
                Looking for something else? View{' '}
                {getBannerProduct(abandonedSelection)} deals similar to this{' '}
                {abandonedSelection.headline}
              </Text>
            }
          />
        </Flex>
      </HighlightedFlex>
    )
  )
}

export default function TripGrouping({
  tripGroupingBooking,
  tripGroupingAbandonedSelection,
  defaultAbandonedSelection
}: TripGroupingProps) {
  return (
    <>
      {tripGroupingBooking && tripGroupingAbandonedSelection && (
        <Box mb={4}>
          <GroupedTrip
            booking={tripGroupingBooking}
            abandonedSelection={tripGroupingAbandonedSelection}
          />
        </Box>
      )}

      {tripGroupingBooking && !tripGroupingAbandonedSelection && (
        <Box mb={4}>
          <CrossSell booking={tripGroupingBooking} />
        </Box>
      )}
      <TripActivityModule
        shouldUseSmallerHeading={Boolean(tripGroupingBooking)}
        hideAbandonedSelection={Boolean(tripGroupingAbandonedSelection)}
        defaultAbandonedSelection={defaultAbandonedSelection}
        isTripGroupingExperiment
      />
    </>
  )
}
