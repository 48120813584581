import React from 'react'
import { Flex } from 'pcln-design-system'
import styled from 'styled-components'
import { HighlightedFlex } from '@pcln/brand'
import { useFeature } from '@pcln/feature-components'

const HighlightedFlexStyle = styled(HighlightedFlex)`
  gap: 16px;
`

export default function PromoFeatureBox({
  children
}: {
  children: React.ReactNode
}) {
  const highlightColor = useFeature('siteExperience.highlightColor')

  return (
    <Flex mx="auto" justifyContent="center">
      <HighlightedFlexStyle
        shadowOffsetX={24}
        shadowOffsetY={24}
        width={1}
        flexWrap={['wrap', null, 'unset']}
        shadowColor={highlightColor}
      >
        {children}
      </HighlightedFlexStyle>
    </Flex>
  )
}
