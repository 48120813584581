import React from 'react'
import { Box, Flex } from 'pcln-design-system'
import { OptInCard } from '@pcln/marketing'
import { ActionCard } from '@pcln/cards'
import { Bag, Help } from 'pcln-icons'
import useHandleAppDownloadSubmit from '@/hooks/useHandleAppDownloadSubmit'
import useBootstrapData from '@/hooks/useBootstrapData'
import { trackMyTrips, trackHelpCenter, CONTENT } from './utils'

export default function AppDownloadOptIn() {
  const [handleSubmitNumber, signUpState] = useHandleAppDownloadSubmit()
  const { signInStatus } = useBootstrapData()
  return (
    <Flex>
      <Box mr={3}>
        <OptInCard
          {...CONTENT}
          desktopImageAlignment="right"
          signUpState={signUpState}
          handleFormSubmit={handleSubmitNumber}
        />
      </Box>
      <Flex flexDirection="column" style={{ gap: 16 }}>
        <ActionCard
          headingWidth="300px"
          icon={<Bag color="primary" />}
          heading="My Trips"
          caption="Quickly find your booking reservations and redeem flight credits"
          isIconRounded={false}
          onClick={() => trackMyTrips(signInStatus.signedIn)}
          handleSubmitCTA={() => trackMyTrips(signInStatus.signedIn)}
        />
        <ActionCard
          headingWidth="300px"
          icon={<Help />}
          heading="Help Center"
          isIconRounded={false}
          caption="We are always Here for you - reach us 24hrs a day, 7 days a week"
          onClick={trackHelpCenter}
          handleSubmitCTA={trackHelpCenter}
        />
      </Flex>
    </Flex>
  )
}
