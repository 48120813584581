import React from 'react'
import { Booking } from './types'
import { BookedItemApiResponse } from '../TripGrouping/types'
import { buildContent, buildContentExperiment } from './utils'
import UpcomingTrip from './UpcomingTrip'
import { isTripGroupingBooking } from '../TripGrouping/utils'

export default function CrossSell({
  booking
}: {
  booking: Booking | BookedItemApiResponse
}) {
  const { xSellUrl, xSellTargetId } = isTripGroupingBooking(booking)
    ? buildContentExperiment(booking)
    : buildContent(booking)

  return (
    xSellUrl &&
    xSellTargetId && (
      <UpcomingTrip
        booking={booking}
        xSellUrl={xSellUrl}
        xSellTargetId={xSellTargetId}
      />
    )
  )
}
