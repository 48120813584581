import React from 'react'
import MediaCardComponent from '@/components/Promos/MediaCard/MediaCardComponent'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import { isIMediaCard } from '@/shared-utils/content-model-type-guards'
import CONTENT from './content'

export default function PriceBreakers() {
  const promo = useContentfulVariation('leftPromoBox', isIMediaCard)
  const hasContentfulData = promo != null
  const mediaCardContent = hasContentfulData ? promo : CONTENT
  // MediaCardComponent requires contentful data to render. CONTENT is the fallback here.
  // The value for the body field is a React Element in the fallback content, hence the mismatch
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return <MediaCardComponent content={mediaCardContent} />
}
