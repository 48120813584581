import { LOCATION_SEARCH_TYPE } from '@/types'
import packagesSearch from '@/shared-utils/packages-search'
import {
  CAR_SEARCH_DATA,
  persistSearchData
} from '@/shared-utils/local-forage-helper'
import fireCarsFormGA4Event from '../ga4'
import { DRIVE, FLY, STAY } from '../constants'
import { CAR_FORM_STATE_TYPE } from '../types'

export const initialState: CAR_FORM_STATE_TYPE = {
  adultCount: 2,
  childrenAges: [],
  childrenCount: 0,
  endDate: '',
  endLocation: null,
  endTime: '12:00',
  hotelEndDate: '',
  hotelStartDate: '',
  oneWay: false,
  roomCount: 1,
  startDate: '',
  startLocation: null,
  startTime: '12:00',
  tripType: DRIVE
}

export const fields = [
  'adultCount',
  'childrenAges',
  'childrenCount',
  'endDate',
  'endLocation',
  'endTime',
  'hotelEndDate',
  'hotelStartDate',
  'oneWay',
  'roomCount',
  'startDate',
  'startLocation',
  'startTime',
  'tripType'
] as const

function formatCarsPartnerLocation(location: LOCATION_SEARCH_TYPE | null) {
  if (!location) return null
  if (location.type === 'AIRPORT' || location.type === 'PARTNER_LOC') {
    return location.id
  }

  // Same logic as how drive-search-responsive formats the itemName
  return location.itemName?.replace(/[^a-zA-Z0-9,-]+/g, '%20')
}

export function formatCarsURL({
  endDate,
  endLocation,
  endTime = '12:00',
  oneWay = false,
  startDate,
  startLocation,
  startTime = '12:00'
}: {
  endDate: string
  endLocation: LOCATION_SEARCH_TYPE | null
  endTime: string
  oneWay: boolean
  startDate: string
  startLocation: LOCATION_SEARCH_TYPE | null
  startTime: string
}) {
  const formattedStartLocation = formatCarsPartnerLocation(startLocation)
  const formattedEndLocation = oneWay
    ? formatCarsPartnerLocation(endLocation)
    : formattedStartLocation

  return [
    '/drive/search/r',
    formattedStartLocation,
    formattedEndLocation,
    `${startDate}-${startTime}`,
    `${endDate}-${endTime}`,
    'list'
  ].join('/')
}

export function handleSubmitForCars(values: CAR_FORM_STATE_TYPE) {
  const {
    adultCount,
    childrenAges,
    childrenCount,
    endLocation,
    hotelEndDate,
    hotelStartDate,
    roomCount,
    tripType,
    startLocation
  } = values
  const multiOccFields = {
    adultCount,
    childrenCount,
    childrenAges
  }
  const isPackageSearch = tripType.includes(STAY) || tripType.includes(FLY)
  const hasFlight = tripType.includes(FLY)

  if (isPackageSearch) {
    const basePackageSearchDetails = {
      endLocationObject: endLocation,
      endDate: hotelEndDate,
      startDate: hotelStartDate,
      isPartialStayDateValid: false,
      roomCount: Number(roomCount),
      tripType,
      ...multiOccFields
    }

    const flightSearchDetails = {
      startLocationObject: startLocation,
      ...multiOccFields
    }
    const packageSearchDetails = {
      ...basePackageSearchDetails,
      ...(hasFlight ? flightSearchDetails : {})
    }
    packagesSearch(packageSearchDetails)
  } else {
    fireCarsFormGA4Event(initialState, values)
    const url = formatCarsURL(values)
    window.open(url)
  }

  void persistSearchData(CAR_SEARCH_DATA, values)
}
