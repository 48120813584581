import React from 'react'
import { Text } from 'pcln-design-system'
import analytics from '@/shared-utils/analytics'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'

export const CONTENT = {
  heading: 'Our Best Deals are in the App',
  subheading:
    'Discover hotel, flight and rental car deals exclusively in the app. Download today to stay connected with important trip details.',
  successMessage: (
    <Text>
      We&apos;ve sent a link to the number you provided. Happy Savings!
    </Text>
  ),
  failureMessage: 'Please check the number you have entered',
  imageUrl:
    'https://s1.pclncdn.com/design-assets/next-landing/assets/images/app-download/opt-in-download-qr-code-hp.png?opto&auto=avif,webp&width=160&height=176',
  altImageText: 'app screenshot',
  placeholderText: '+1 (###) ###-####',
  type: 'SMS',
  buttonText: 'Send Link'
}

export function trackMyTrips(isSignedIn: boolean) {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_HOME,
    action: 'promo trio',
    label: 'my trips'
  })
  if (isSignedIn) {
    window.open('https://www.priceline.com/next-profile/trips')
  } else {
    window.open('https://www.priceline.com/next-profile/trip-lookup')
  }
}

export function trackHelpCenter() {
  analytics.fireEvent({
    category: ANALYTICS_CATEGORY_HOME,
    action: 'promo trio',
    label: 'help center'
  })
  window.open('https://help.priceline.com/')
}
