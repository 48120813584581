import { complement, either, isNil, isEmpty } from 'ramda'
import useBootstrapData from './useBootstrapData'

const isTruthy = complement(either(isNil, isEmpty))

export default function useMostRecentFutureBooking() {
  const { mostRecentBooking } = useBootstrapData()
  const mostRecentFutureBooking = isTruthy(mostRecentBooking?.future)
    ? mostRecentBooking?.future
    : null

  return {
    mostRecentFutureBooking
  }
}
