import React from 'react'
import styled from 'styled-components'
import { Image, Box } from 'pcln-design-system'
import useMobileHeroCityImage from '@/hooks/useMobileHeroCityImage'
import useMobileHeroAmenityImage from '@/hooks/useMobileHeroAmenityImage'

const ImageWrapper = styled(Box)`
  max-height: 110px;
  overflow: hidden;
`

export default function HeroMobile() {
  const mobileHeroCityImage = useMobileHeroCityImage()
  const mobileHeroAmenityImage = useMobileHeroAmenityImage()
  const mobileHeroImage = mobileHeroCityImage || mobileHeroAmenityImage

  return mobileHeroImage ? (
    <ImageWrapper borderRadius="lg" m={3}>
      <Image
        src={mobileHeroImage.src}
        width="393px"
        height="131px"
        alt={`Picture of ${mobileHeroImage.altName}`}
        className="mobile-hero-image"
      />
    </ImageWrapper>
  ) : null
}
