import { format } from 'date-fns/format'
import { parseISO } from 'date-fns/parseISO'
import type {
  BookedItemApiResponse,
  BookedHotelApiResponse,
  BookedFlightApiResponse,
  BookedRentalCarApiResponse
} from './types'

function formatISODate(date: string, showTime?: boolean) {
  return format(parseISO(date), `EEE, MMM dd${showTime ? " 'at' h:mm a" : ''}`)
}

export function isTripGroupingBooking(
  booking: unknown
): booking is BookedItemApiResponse {
  if (typeof booking === 'object' && !!booking && 'productType' in booking) {
    const { productType } = booking
    return (
      // Booking type has no productType property so this check should suffice
      productType === 'STAY' || productType === 'FLY' || productType === 'DRIVE'
    )
  }

  return false
}

export function isStayBooking(
  payload: BookedItemApiResponse
): payload is BookedHotelApiResponse {
  return payload.productType === 'STAY'
}

export function isFlyBooking(
  payload: BookedItemApiResponse
): payload is BookedFlightApiResponse {
  return payload.productType === 'FLY'
}

export function isDriveBooking(
  payload: BookedItemApiResponse
): payload is BookedRentalCarApiResponse {
  return payload.productType === 'DRIVE'
}

export function buildTripGroupingContent(booking: BookedItemApiResponse) {
  if (isStayBooking(booking)) {
    return {
      productType: 'STAY',
      headline: `${formatISODate(
        booking.travelStartDateTime
      )} - ${formatISODate(booking.travelEndDateTime)}`,
      locationCopy: booking.cityName,
      subHeadline: `Check-in on ${formatISODate(booking.travelStartDateTime)}`,
      cityName: booking.cityName,
      itineraryUrl: booking.itineraryUrl
    }
  }

  if (isFlyBooking(booking)) {
    return {
      productType: 'FLY',
      headline: `${formatISODate(
        booking.travelStartDateTime
      )} - ${formatISODate(booking.travelEndDateTime)}`,
      locationCopy: `${booking.originAirportCode} - ${booking.destinationAirportCode}`,
      subHeadline: `Departs ${formatISODate(
        booking.travelStartDateTime,
        true
      )}`,
      cityName: booking.destinationCityName,
      itineraryUrl: booking.itineraryUrl
    }
  }

  if (isDriveBooking(booking)) {
    return {
      productType: 'DRIVE',
      headline: `${formatISODate(
        booking.travelStartDateTime
      )} - ${formatISODate(booking.travelEndDateTime)}`,
      locationCopy: booking.pickupLocationCityName,
      subHeadline: `Pick-up on ${formatISODate(
        booking.travelStartDateTime,
        true
      )}`,
      cityName: booking.pickupLocationCityName,
      itineraryUrl: booking.itineraryUrl
    }
  }
}
