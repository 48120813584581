import analytics from '@/shared-utils/analytics'

export default function fireOnSubmitEvent(data: string) {
  analytics.fireGA4Event({
    event: 'search',
    attributes: {
      product_name: 'cruise',
      page_name: 'homepage',
      item_name: data
    }
  })
}
