import { useContext, useState } from 'react'
import BootstrapDataContext from '@/context/BootstrapDataContext'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import analytics from '@/shared-utils/analytics'

export default function useHandleAppDownloadSubmit() {
  const {
    webstats: { clientGUID },
    locale: { ipAddr },
    referral: { clickId, id, sourceId }
  } = useContext(BootstrapDataContext)
  const [signUpState, setSignUpState] = useState('NOT_SIGNED_UP')

  type FormValues = {
    phoneNumber: string
  }

  function formatPhoneNumber(phoneNumber: string) {
    return phoneNumber
      .replace(/[^\d]/g, '')
      .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$2-$3-$4')
  }

  const handleSubmitNumber = async (values: FormValues) => {
    const { phoneNumber } = values
    const formattedNumber = formatPhoneNumber(phoneNumber)
    const validFormat = /^\d{3}[-]\d{3}[-]\d{4}$/.test(formattedNumber)
    const URL = '/svcs/eng/gblsvcs/v1/sms/send'
    if (validFormat) {
      const submission = {
        cguid: clientGUID,
        guid: 'aaa1289772a44ef6bbec486b0c907c11',
        ipAddr,
        pageId: 'HP_TRIO',
        refClickId: clickId,
        refId: id,
        refSourceId: sourceId,
        smsContent: 'TEST',
        toPhoneNo: formattedNumber
      }
      try {
        setSignUpState('SIGN_UP_IN_PROGRESS')
        const response = await fetch(URL, {
          method: 'POST',
          body: JSON.stringify(submission),
          headers: { 'content-type': 'application/json' }
        })
        if (response.ok) {
          const body = await response.json()
          if (body.messageSent) {
            analytics.fireEvent({
              category: ANALYTICS_CATEGORY_HOME,
              action: 'promo trio',
              label: 'app download'
            })
            setSignUpState('SIGNED_UP')
          } else {
            setSignUpState('SIGN_UP_ERROR')
            throw new Error(body.errorDesc || 'Phone registration failure')
          }
        } else {
          setSignUpState('SIGN_UP_ERROR')
          throw new Error(response.statusText || 'Phone registration failure')
        }
      } catch (err) {
        setSignUpState('SIGN_UP_ERROR')
      }
    } else {
      setSignUpState('SIGN_UP_ERROR')
    }
  }

  return [handleSubmitNumber, signUpState]
}
